import React, { useState } from 'react';
import { Button, ConfigProvider, Drawer, Radio, Space, } from 'antd';
import { BarsOutlined, CloseOutlined, FilterFilled } from '@ant-design/icons';
import SideManubar from './SideManubar';
import { Colors } from '../theme/Thems';
const FilterDrawer = (props) => {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    return (
        <>
            <Button className='flex items-center' type="link" onClick={showDrawer}>
                Filter <FilterFilled />
            </Button>

            <Drawer
                // title="Basic Drawer"
                placement={placement}
                closable={false}
                onClose={onClose}
                open={open}
                key={placement}
                width={450}
            >
                <div> <CloseOutlined className=' cursor-pointer' onClick={onClose} /></div>
                <div className='flex justify-end gap-5 pr-5'>
                    <Button onClick={props.applyFilter} type='primary'>Apply</Button>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    /* here is your component tokens */
                                    defaultBg: Colors?.secondryBtnBg,
                                    defaultBorderColor: "none",
                                    defaultColor: Colors.primaryColor
                                },
                            },
                        }}
                    >

                        <Button onClick={props.reset}>Reset</Button>
                    </ConfigProvider>
                </div>
                {props.components}
            </Drawer>
        </>
    );
};
export default FilterDrawer;