import React, { useRef } from 'react'
import SideManubar from '../components/SideManubar'
import { Outlet, useLocation } from 'react-router-dom'
import { H1 } from '../theme/StylingComponent'
import Breadcrams from '../components/Breadcrams'
import DrawerNav from '../components/DrawerNav'
import { capitalizeAllWords } from '../Services/api-services'

export default function Home() {
    const BoxRef = useRef();
    const location = useLocation();

    React.useEffect(() => {
        const currentPath = location.pathname?.split('/');
        let lastElement = currentPath[currentPath?.length - 1];
    
        document.title = capitalizeAllWords(lastElement);
    }, [location.pathname])
    return (
        <div className='flex flex-1 md:flex-row flex-col  '>
            <div ref={BoxRef} className=' hidden md:flex border-r border-[#0000001A] shadow  justify-center md:min-w-[25vw] lg:min-w-[20vw] xl:min-w-[15vw] md:w-[25vw] lg:w-[20vw] xl:w-[15vw]'>
                <SideManubar />
            </div>
            <div className='flex md:hidden ' >

                <DrawerNav />
            </div>
            <div className=' flex flex-1 flex-col px-[1vw] overflow-auto max-h-screen'>
                {/* <Breadcrams /> */}
                <div className=' h-10'>

                </div>
                <Outlet />
            </div>
        </div>
    )
}
