import { Col, ConfigProvider, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment/moment';
import { get, post } from '../../Services/api-services';
import FilterDrawer from '../../components/FilterDrawer';
import SalesFilter from '../../components/SalesFilter';
import { Colors } from '../../theme/Thems';
import { LeftOutlined } from '@ant-design/icons';

const columns = [

    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
    },
    {
        title: 'Joined On',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        render: (text) => <p>{moment(new Date(text)).format("MMM Do YY")}</p>,

    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 300,
    },
    {
        title: 'Phone Number',
        dataIndex: 'mobile',
        key: 'mobile',
        width: 180,
    },

    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: 180,
    },

];
const col = [
    {
        title: 'Transaction Date & Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 300,
        render: (text) => <p>{moment(new Date(text)).format("MMM Do YY")}</p>,

    },
    {
        title: 'Plan',
        dataIndex: 'toPack',
        key: 'toPack',
        width: 200,
    },

    {
        title: 'Purpose',
        dataIndex: 'email',
        key: 'email',
        width: 300,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 180,
    },

    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 180,
    },

];
export default function SalesDetails() {
    const location = useLocation();
    const { member, toPack, createdAt, teamId, team, name } = location?.state;

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoding] = useState(true);

    const [dateRange, setDateRange] = useState();
    const [dataSourceBill, setDataSourceBill] = useState([]);
    const [loadinsg, setLodings] = useState(true)
    const [filterData, setFilterData] = useState({ teamId: teamId });
    const [total, setTotal] = useState(0);
    const [owner, setOwnerData] = useState()
    const navigate = useNavigate();

    const getUsers = async () => {
        setLoding(true);
        const res = await get(`team/${teamId}/user`);
        if (res?.statusCode === 200) {
            setDataSource(res?.data);
        }
        setLoding(false);

    }
    const getOwnerData = async () => {
        const res = await get(`team/${teamId}/user/${team?.ownerId}`);
        if (res?.statusCode === 200) {
            setOwnerData(res?.data)
        }
    }
    const getTeamsData = async (data) => {
        setLoding(true);
        try {
            const res = await post('admin/getSalesList', data);
            if (res?.statusCode === 200) {
                setDataSourceBill(res?.data?.data);
                setTotal(res?.data?.FilterCount);

            } else {

            }
        } catch (e) {

        }
        setLoding(false)
    }

    useEffect(() => {
        getTeamsData({ dateFilter: dateRange, filterData: filterData, });
        console.log('i fire once');

    }, [dateRange])
    const applyFilter = async () => {
        getTeamsData({ dateFilter: dateRange, filterData: filterData, });
    }


    const reset = async () => {
        setFilterData()
        getTeamsData({ filterData: { teamId: teamId } });

    }






    useEffect(() => {
        getUsers();
        getOwnerData();
    }, [])
    return (
        <div className='flex flex-col gap-10 md:gap-16' >
            <div className='pt-5'>
                <div onClick={() => navigate(-1)} className='flex items-center cursor-pointer'>  <LeftOutlined /> {team?.name ? team?.name : "Team Detail"}</div>
            </div>
            <div>


                <Row className='flex justify-between gap-10 flex-wrap'>
                    <Col xs={24} sm={24} md={24} lg={11}>
                        <Row>
                            <Col className={`py-3 `} span={8}>
                                <p style={{ color: Colors?.LightText, fontWeight: 500 }}>Team Details</p>
                            </Col>

                        </Row>

                        <Col className={` border  border-[${Colors?.LightTextTwo}]   `}>

                            <Row className={`flex border-b h-10 border-[${Colors?.LightTextTwo}] `}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Members</p>
                                </Col>

                                <Col className='  flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{team?.member}</p>
                                </Col>
                            </Row>
                            <Row className={`flex border-b h-10 border-[${Colors?.LightTextTwo}]`}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>No. of Docs</p>
                                </Col>

                                <Col className='  flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{team?.numberOfDocument}</p>
                                </Col>
                            </Row>
                            <Row className={`flex border-b h-10 border-[${Colors?.LightTextTwo}]`}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Current Plan</p>
                                </Col>

                                <Col className=' flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{team?.plan?.title}</p>
                                </Col>
                            </Row>
                            <Row className={`flex border-b h-10 border-[${Colors?.LightTextTwo}]`}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Current Status</p>
                                </Col>

                                <Col className='flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>Amount</p>
                                </Col>

                            </Row>
                            <Row className={` h-10`}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Created On</p>
                                </Col>
                                <Col className=' flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{moment(new Date(team?.createdAt)).format("MMM Do YY")}</p>
                                </Col>
                            </Row>

                        </Col>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={11}>
                        <Row>
                            <Col className=' py-3 ' span={8}>
                                <p style={{ color: Colors?.LightText, fontWeight: 500 }}>Owner Details</p>
                            </Col>

                        </Row>
                        <Col className={` border  border-[${Colors?.LightTextTwo}]   `} >
                            <Row className={`flex border-b h-10 border-[${Colors?.LightTextTwo}] `}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Name</p>
                                </Col>

                                <Col className='  flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{owner?.name}</p>
                                </Col>
                            </Row>
                            <Row className={`flex border-b h-10 border-[${Colors?.LightTextTwo}]`}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Email</p>
                                </Col>

                                <Col className='  flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{owner?.email}</p>
                                </Col>
                            </Row>
                            <Row className={`flex border-b h-10 border-[${Colors?.LightTextTwo}]`}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Phone Number</p>
                                </Col>

                                <Col className=' flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{owner?.mobile}</p>
                                </Col>
                            </Row>
                            <Row className={`flex border-b h-10 border-[${Colors?.LightTextTwo}]`}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Country</p>
                                </Col>

                                <Col className='flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{owner?.country?.label}</p>
                                </Col>

                            </Row>
                            <Row className={` h-10`}>
                                <Col className='flex items-center pl-3 border-r ' span={8}>
                                    <p>Organization</p>
                                </Col>
                                <Col className=' flex items-center pl-3' span={11}>
                                    <p style={{ color: Colors?.LightTextTwo }}>{team?.name}</p>
                                </Col>
                            </Row>

                        </Col>
                    </Col>
                </Row>
            </div>
            <div>
                <p style={{ color: Colors?.LightText, fontWeight: 500 }} className='py-3'>
                    User Data
                </p>
                <div className='flex flex-1 md:max-w-[73vw] lg:max-w-[78vw]  xl:max-w-[83vw]   ' >

                    <ConfigProvider
                        theme={{
                            components: {
                                Table: {
                                    /* here is your component tokens */
                                    borderColor: "none",
                                    borderColor: "none",
                                    headerColor: Colors.DarkBg,
                                    colorText: Colors.LightText
                                },
                            },
                        }}
                    >
                        < Table
                            scroll={{
                                x: 300
                            }} loading={loading}
                            className='flex-1'
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}


                        />
                    </ConfigProvider>
                </div>
            </div>
            <div>
                <p style={{ color: Colors?.LightText, fontWeight: 500 }} className='py-3'>
                    Billing Details
                </p>
                <div className='flex justify-end pb-10' ><FilterDrawer applyFilter={applyFilter} reset={reset} components={< SalesFilter setFilterData={setFilterData} filterData={filterData} />} /></div>

                <div className='flex flex-1 md:max-w-[73vw] lg:max-w-[78vw]  xl:max-w-[83vw]   ' >

                    <ConfigProvider
                        theme={{
                            components: {
                                Table: {
                                    /* here is your component tokens */
                                    borderColor: "none",
                                    borderColor: "none",
                                    headerColor: Colors.DarkBg,
                                    colorText: Colors.LightText
                                },
                            },
                        }}
                    >
                        < Table
                            scroll={{
                                x: 300
                            }} loading={loading}
                            className='flex-1'
                            dataSource={dataSourceBill}
                            columns={col}
                            pagination={false}


                        />
                    </ConfigProvider>
                </div>
            </div>
            <div className=' h-16'>

            </div>
        </div >
    )
}
