import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';


const ConfirmLogout = ({ open, setOpen }) => {
    const navigate = useNavigate();

    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [isLoading, setIsLoading] = React.useState(false)

    // console.log(location?.pathname?.split('/')?.length);
    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };
    const handleLogout = async () => {
        setAuthState();
        navigate('/login')
    }


    return (
        <>
            <Modal
                title={<h1>Are you sure you want to log out?</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                closable={false}
                style={modalContentStyle}
                footer={
                    <div className='w-full flex items-center justify-end gap-4 pt-6'>
                        <Button
                            onClick={() => setOpen(false)}
                            size='middle'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleLogout}
                            // style={{ backgroundColor: themeColors.bg.inviteButton }}
                            size='middle'
                            type="primary"
                            loading={isLoading}
                        >
                            Logout
                        </Button>
                    </div>
                }
                header={null}
                width={500}
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem corporis fuga beatae non itaque quo labore, ad dolorum aspernatur debitis.</h1>
                </div>
            </Modal>
        </>
    );
};
export default ConfirmLogout;