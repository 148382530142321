import React, { useEffect, useState } from 'react'
import { H2 } from '../../theme/StylingComponent'
import { DatePicker } from 'antd';
import Tables from '../../components/Tables';
import { post } from '../../Services/api-services';
import FilterDrawer from '../../components/FilterDrawer';
import moment from 'moment/moment';
import SalesFilter from '../../components/SalesFilter';
import { useNavigate } from 'react-router-dom';
const columns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => <p>{moment(new Date(text)).format("MMM Do YY")}</p>,
    width: 150,
  },
  {
    title: 'Sales Type',
    dataIndex: 'type',
    key: 'type',
    width: 150,
  },
  {
    title: 'Payment Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
  },
  {
    title: 'Team Name',
    dataIndex: 'TeamName',
    key: 'TeamName',
    width: 150,
  },
  {
    title: 'Plan From',
    dataIndex: 'fromPack',
    key: 'toPack',
    width: 150,
  },
  {
    title: 'Plan To',
    dataIndex: 'toPack',
    key: 'toPack',
    width: 150,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 100,
  },


];

export default function SalesList() {
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoding] = useState(true)
  const [filterData, setFilterData] = useState();
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 })

  const navigate = useNavigate();

  const getTeamsData = async (data) => {
    setLoding(true);
    try {
      const res = await post('admin/getSalesList', data);
      if (res?.statusCode === 200) {
        setDataSource(res?.data?.data);
        setTotal(res?.data?.FilterCount);

      } else {

      }
    } catch (e) {

    }
    setLoding(false)
  }

  useEffect(() => {
    getTeamsData({ dateFilter: dateRange, filterData: filterData, pagination: pagination });
    console.log('i fire once');

  }, [dateRange, pagination])
  const applyFilter = async () => {
    getTeamsData({ dateFilter: dateRange, filterData: filterData, pagination: pagination });
  }
  const reset = async () => {
    setFilterData()
    getTeamsData({});

  }

  const setData = async ({ record, rowIndex }) => {
    try {
      console.log(record)
      navigate(record?.name ? record?.name?.replace(/\s/g, '_') : "Team_Detail", { state: record })
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div className='flex flex-1 flex-col gap-5 ' style={{ maxWidth: "100%" }}>
      <div className='flex w-full justify-between flex-wrap'>
        <H2 title={"Sales"} />
        <div>

          <RangePicker
            style={{ borderRadius: 3 }}
            onChange={(value, dateString) => { setDateRange(dateString) }}
          />
        </div>
      </div>
      <div className='flex justify-end pb-10' ><FilterDrawer applyFilter={applyFilter} reset={reset} components={< SalesFilter setFilterData={setFilterData} filterData={filterData} />} /></div>


      <Tables dataSource={dataSource} loading={loading} total={total} columns={columns} setData={setData} setPagination={setPagination} />


    </div >
  )
}
