// SettingDrawer.js
import React from 'react'
import { BillingIcon, HelpIcon, LeftArrowIcon, LogoutIcon, PeopleIcon, Teams, UserIcon, coupon, dashboard, sales, settings } from './Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Colors } from '../theme/Thems';
import { H1 } from '../theme/StylingComponent';
import ConfirmLogout from './ConfirmLogout';
const signizyLogo = require('../Assets/SignizyLogo.png')


const SideManubar = ({ currentUser }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const url = '/settings'
    const [open, setOpen] = React.useState(false)

    // DRAWER OPTIONS 
    const options = [
        {
            label: 'Dashboard',
            icon: dashboard,
            route: 'Dashboard'
        },
        {
            label: 'Teams',
            icon: Teams,
            route: 'Teams'
        },
        {
            label: 'Sales',
            icon: sales,
            route: 'Sales'
        },
        {
            label: 'Coupon',
            icon: coupon,
            route: 'Coupons'
        },
        {
            label: 'Settings',
            icon: settings,
            route: 'Settings'
        },

        {
            label: 'Logout',
            icon: LogoutIcon,
            route: 'Logout'
        },
    ];

    // Filter out the 'Billing' option if the current user's role is 'moderator'
    const filteredOptions = currentUser?.role === 'moderator'
        ? options.filter(option => option.route !== 'billing')
        : options;


    const handleClick = (el) => {
        if (el?.route === 'Logout') {
            setOpen(true)

        } else {
            navigate(`${el?.route}`)
        }
    }

    return (
        <>
            <div className={`w-full  h-full  flex flex-col flex-1  }`}>
                <div className='flex items-center  h-[15vh] justify-center'>
                    <img className=' h-12' src={signizyLogo} />
                </div>
                <div className='w-full flex   justify-center flex-1 '>

                    <div className='  flex flex-col  px-3 gap-0 sm:gap-6 py-4 lg:pt-6'>
                        {filteredOptions?.map((el, idx) => {
                            const iconColor = location?.pathname?.includes(el?.route) || (location?.pathname?.split('/')?.length === 2 && el?.route === 'Dashboard') ? Colors?.DarkBg : Colors.LightTextTwo
                          
                            return (
                                <div onClick={() => handleClick(el)} key={idx} className={` px-5  ${location?.pathname?.includes(el?.route) || (location?.pathname?.split('/')?.length === 2 && el?.route === 'Dashboard') ? 'shadow' : ""} cursor-pointer rounded p-3 my-2 flex items-center gap-4  ${location?.pathname?.includes(el?.route) ? "#3366FF" : 'red'}`}>
                                    <el.icon color={iconColor} />
                                    <h6 style={{ color: iconColor }} className={`text-lg lg:text-base font-normal tracking-wide ] `}>{el.label}</h6>
                                    {/* <h6 className={` text-[${iconColor}]`}>{el.label}</h6> */}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div >

            <ConfirmLogout
                open={open}
                setOpen={setOpen}
            />
        </>
    )
}

export default SideManubar;

