import { Card, Col, DatePicker, Divider, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { G2, G3, G4, G5, G6, G7, G8, Gperson, Person } from '../components/Icons'
import { H1, H2, H3 } from '../theme/StylingComponent'
import { Colors } from '../theme/Thems'
import { get, post } from '../Services/api-services'
import dayjs from 'dayjs'
import moment from 'moment'

export default function Dashboard() {
    const [dashboardData, setDashboardData] = useState();
    const [dashboardStatics, setDashboardStatics] = useState();

    let startDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
    let endDate = new Date();
    const [dates, setDates] = useState([moment(startDate), moment(endDate)]);

    const getDashBoard = async () => {
        const res = await get('admin/getDashboardData');
        if (res?.statusCode === 200) {
            setDashboardData(res?.data)
        }
    }

    const getDashboardStatics = async (date) => {
        const res = await post('admin/getDashboardStatics', { dateFilter: date });
        if (res?.statusCode === 200) {
            setDashboardStatics(res?.data)
        }
    }
    useEffect(() => {
        getDashBoard();
    }, [])
    useEffect(() => {
        getDashboardStatics(dates);
    }, [dates])
    const { RangePicker } = DatePicker;

    return (
        <div className='flex flex-1 flex-col gap-10'>
            <div className='pt-10'>
                <H2 title={"Dashboard"} />
            </div>

            <Row className='flex gap-10 lg:justify-between' >
                <Col xs={24} sm={11} md={11} lg={7} xl={5}>
                    <Card
                        className=' shadow-xl border-b-8 border-b-[#3366FF]'
                    >
                        <div className=' h-[30vh] flex flex-col justify-between'>
                            <div className='flex h-1/2 flex-col'>
                                <div className=' flex justify-between items-center'>
                                    <p style={{ color: Colors?.LightTextTwo }}>Total Teams</p>
                                    <Gperson />
                                </div>
                                <div className='flex   flex-1 '>

                                    <p className=' self-center ' style={{ color: Colors?.DarkBg, fontSize: 50, fontWeight: 700 }}>{dashboardData?.totalTeam}</p>
                                </div>
                            </div>
                            <Divider dashed />
                            <div className='flex flex-1 flex-col'>
                                <div className=' flex justify-between items-center'>
                                    <p style={{ color: Colors?.LightTextTwo }}>Total Users</p>
                                    <Person />
                                </div>
                                <div className='flex   flex-1 '>

                                    <p className=' self-center ' style={{ color: Colors?.DarkBg, fontSize: 50, fontWeight: 700 }}>{dashboardData?.totalUser}</p>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>

                {/* <Col xs={24} sm={11} md={11} lg={7} xl={5}>
                    <Card
                        className=' shadow-xl border-b-8 border-b-[#FAA41A]'
                    >
                        <div className=' h-[30vh] flex flex-col justify-between'>
                            <div className='flex h-1/2 flex-col'>
                                <div className=' flex justify-between items-center'>
                                    <p style={{ color: Colors?.LightTextTwo }}>Total Basic Plan Teams</p>
                                    <G2 />
                                </div>
                                <div className='flex   flex-1 '>

                                    <p className=' self-center ' style={{ color: Colors?.DarkBg, fontSize: 50, fontWeight: 700 }}>15,200</p>
                                </div>
                            </div>
                            <Divider dashed />
                            <div className='flex flex-1 flex-col'>
                                <div className=' flex justify-between items-center'>
                                    <p style={{ color: Colors?.LightTextTwo }}>Active Basic Plan Teams </p>
                                    <G3 />
                                </div>
                                <div className='flex   flex-1 '>

                                    <p className=' self-center ' style={{ color: Colors?.DarkBg, fontSize: 50, fontWeight: 700 }}>98,200</p>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={11} md={11} lg={7} xl={5}>
                    <Card
                        className=' shadow-xl border-b-8 border-b-[#C197F8]'

                    >
                        <div className=' h-[30vh] flex flex-col justify-between'>
                            <div className='flex h-1/2 flex-col'>
                                <div className=' flex justify-between items-center'>
                                    <p style={{ color: Colors?.LightTextTwo }}>Total Premium Plan Teams</p>
                                    <G4 />
                                </div>
                                <div className='flex   flex-1 '>

                                    <p className=' self-center ' style={{ color: Colors?.DarkBg, fontSize: 50, fontWeight: 700 }}>15,200</p>
                                </div>
                            </div>
                            <Divider dashed />
                            <div className='flex flex-1 flex-col'>
                                <div className=' flex justify-between items-center'>
                                    <p style={{ color: Colors?.LightTextTwo }}>Active Basic Plan Teams </p>
                                    <G3 />
                                </div>
                                <div className='flex   flex-1 '>

                                    <p className=' self-center ' style={{ color: Colors?.DarkBg, fontSize: 50, fontWeight: 700 }}>98,200</p>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={11} md={11} lg={7} xl={5}>
                    <Card
                        className=' shadow-xl border-b-8 border-b-[#C197F8]'

                    >
                        <div className=' h-[30vh] flex flex-col justify-between'>
                            <div className='flex h-1/2 flex-col'>
                                <div className=' flex justify-between items-center'>
                                    <p style={{ color: Colors?.LightTextTwo }}>Total Basic Plan Teams</p>
                                    <G5 />
                                </div>
                                <div className='flex   flex-1 '>

                                    <p className=' self-center ' style={{ color: Colors?.DarkBg, fontSize: 50, fontWeight: 700 }}>15,200</p>
                                </div>
                            </div>
                            <Divider dashed />
                            <div className='flex flex-1 flex-col'>
                                <div className=' flex justify-between items-center'>
                                    <p style={{ color: Colors?.LightTextTwo }}>Active Basic Plan Teams </p>
                                    <G3 />
                                </div>
                                <div className='flex   flex-1 '>

                                    <p className=' self-center ' style={{ color: Colors?.DarkBg, fontSize: 50, fontWeight: 700 }}>98,200</p>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col> */}
            </Row>




            <Card className=' mb-20 shadow-xl flex flex-col  h-1/2  ' >
                <div className='flex justify-between items-center'>
                    <H3 title={'Statistics'} />
                    <RangePicker
                        defaultValue={[dayjs(startDate), dayjs(endDate)]}
                        // value={dates}

                        onChange={(e, r) => setDates(r)}
                    />
                </div>
                <Card
                    className=' shadow-xl my-6 bg-black  '
                >


                    <div className='flex justify-between'>
                        <div className=' w-1/3 px-5' style={{ color: Colors?.TextColorInDarkBackground }}>
                            <div className='flex justify-between items-center'>
                                <p>Total Sales</p>
                                <G6 />
                            </div>
                            <p style={{ fontSize: 50, fontWeight: 700 }}>{dashboardStatics?.totalSales}</p>
                        </div>
                        <div className=' border-l-2 border-r-2 w-1/3 border-dashed px-5 ' style={{ color: Colors?.TextColorInDarkBackground }}>
                            <div className='flex justify-between items-center'>
                                <p>New Teams</p>
                                <G7 />
                            </div>
                            <p style={{ fontSize: 50, fontWeight: 700 }}>{dashboardStatics?.totalTeam}</p>
                        </div>
                        <div style={{ color: Colors?.TextColorInDarkBackground }} className=' w-1/3 px-5'>
                            <div className='flex justify-between items-center'>
                                <p>New Users</p>
                                <G8 />
                            </div>
                            <p style={{ fontSize: 50, fontWeight: 700 }}>{dashboardStatics?.totalUser}</p>
                        </div>
                    </div>
                </Card>
                {/* <Card
                    className=' shadow-xl my-6  '
                >


                    <div className='flex justify-between'>
                        <div className=' w-1/3 px-5' style={{ color: Colors?.TextColorInLightBackground }}>
                            <div className='flex justify-between items-center'>
                                <p>Total Sales</p>
                                <G6 />
                            </div>
                            <p style={{ fontSize: 50, fontWeight: 700 }}>89,000</p>
                        </div>
                        <div className=' border-l-2 border-r-2 w-1/3 border-dashed px-5 ' style={{ color: Colors?.TextColorInLightBackground }}>
                            <div className='flex justify-between items-center'>
                                <p>Total Sales</p>
                                <G6 />
                            </div>
                            <p style={{ fontSize: 50, fontWeight: 700 }}>89,000</p>
                        </div>
                        <div style={{ color: Colors?.TextColorInLightBackground }} className=' w-1/3 px-5'>
                            <div className='flex justify-between items-center'>
                                <p>Total Sales</p>
                                <G6 />
                            </div>
                            <p style={{ fontSize: 50, fontWeight: 700 }}>89,000</p>
                        </div>
                    </div>
                </Card> */}

            </Card>

        </div>
    )
}
