import React, { useState } from 'react';
import { Button, Card, ConfigProvider, Drawer, Radio, Space, } from 'antd';
import { BarsOutlined, CloseOutlined, FilterFilled } from '@ant-design/icons';
import SideManubar from './SideManubar';
import { Colors } from '../theme/Thems';
import { AddIcon } from './Icons';
import { H2 } from '../theme/StylingComponent';
const AddPlanDrawer = (props) => {
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        props.setOpen(true);
    };
    const onClose = () => {
        props.setOpen(false);
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    return (
        <>
            {/* <Button className='flex items-center' type="link">
                Filter <FilterFilled />
            </Button> */}
            <ConfigProvider
                theme={{
                    components: {
                        Card: {
                            /* here is your component tokens */
                            actionsBg: "#508BFD17"
                        },
                    },
                }}
            >
                <Card
                    onClick={showDrawer}
                    // title="Default size card"

                    className=' sm:w-[320px]  w-full cursor-pointer '
                // actions={[
                //     <Switch />,
                //     <EditOutlined key="edit" />,
                // ]}
                >
                    <div className=' h-[40vh] sm:h-[40vh] flex justify-center items-center'>
                        <div className=' bg-[#D6E3FD]  rounded-full w-fit p-5'>
                            <AddIcon className='text-[10vh]' />
                        </div>
                    </div>
                </Card>
            </ConfigProvider>
            <Drawer
                // title="Basic Drawer"
                placement={placement}
                closable={false}
                // onClose={onClose}
                open={props.open}
                key={placement}
                width={450}
            >
                <div className=' mb-10'>

                    <H2 title={'Add Subscription'} />
                </div>
                {props.components}

            </Drawer>
        </>
    );
};
export default AddPlanDrawer;