
import "./App.css";
import './index.css';
import { BrowserRouter } from "react-router-dom";

import { RecoilRoot } from 'recoil'
import { createContext, useEffect, useState } from "react";
import MainRoutes from "./Routes/MainRoutes";
import { message } from "antd";






export const successEvents = createContext();
function App() {
  const [me, setMe] = useState("sanjay");
  const [success, setSuccess] = useState("");
  const [filterData, setFilterData] = useState();
  const [currentPage, setCurrentPage] = useState();


  const theme = {
    token: { colorPrimary: '#1F75FE' }
  }
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    messageApi.open({
      type: 'success',
      content: 'This is a prompt message for success, and it will disappear in 10 seconds',
      duration: 10,
    });
  }, [success])
  return (
    <div >
      <successEvents.Provider value={{ success, setSuccess, setCurrentPage, currentPage }}>
        <BrowserRouter>
          <RecoilRoot >
            <div className="h-screen w-screen flex flex-col ">
              < MainRoutes />
            </div>
          </RecoilRoot>
        </BrowserRouter>
      </successEvents.Provider>
    </div>
  );
}

export default App;