import { Button, Col, Divider, Input, InputNumber, Popconfirm, Row, Select, Switch, message } from 'antd'
import React, { useState } from 'react'
import { Colors } from '../theme/Thems'
import { H3 } from '../theme/StylingComponent'
import CountrySelectComponent from './CountrySelectComponent';
import { CloseOutlined } from '@ant-design/icons';
import { patch, post } from '../Services/api-services';
import TextArea from 'antd/es/input/TextArea';

export default function AddPlans({ formData, setFormData, country, setOpen, getPlan, planLength }) {
    const [price, setPrice] = useState({});
    const [selectedCompanyCountry, setSelectedCompanyCountry] = React.useState();
    const [isCompanySelected, setIsCompanySelected] = React.useState(false);

    const handleClose = async () => {
        setFormData({ fileUpload: false, mobileSign: false });
        setOpen(false)
        setSelectedCompanyCountry();
        setPrice();


    }
    const handleEdit = async () => {
        let array = [...formData?.price]
        let check = array.some(obj => obj.hasOwnProperty("USD"));
        if (!check) {
            message.error("Defualt currency is USD , Please add USD price")
        } else {
            let body = { ...formData }
            let id = body?._id;
            delete body?._id
            delete body?.createdAt
            delete body?.updateAt
            if (!body?.fileUpload) {
                delete body?.storage
            }

            let price = [...body?.price]
            let Final = {};
            for (let index = 0; index < price.length; index++) {
                const element = price[index];
                Final = { ...Final, [element.id]: element[element.id] }

            }
            body.price = Final;
            console.log(body)


            const res = await patch(`admin/plan/${id}`, body);
            if (res) {
                getPlan();
                setOpen(false);
                setFormData({ fileUpload: false, mobileSign: false, popular: false });
                setPrice();
                setSelectedCompanyCountry();
                message.success("Plan Updated")
            } else {
                message.error("Somthing wrong")
            }

        }

    }
    const handleAddPlan = async () => {
        let array = [...formData?.price]
        let check = array.some(obj => obj.hasOwnProperty("USD"));
        if (!check) {
            message.error("Defualt currency is USD , Please add USD price")
        } else {
            let body = { ...formData }

            let price = [...body?.price]
            let Final = {};
            for (let index = 0; index < price.length; index++) {
                const element = price[index];
                Final = { ...Final, [element.id]: element[element.id] }

            }
            body.price = Final;
            body.rank = planLength + 1
            if (!body?.fileUpload) {
                delete body?.storage
            }


            const res = await post(`admin/plan`, body);
            if (res) {
                getPlan();
                setFormData({ fileUpload: false, mobileSign: false, popular: false });
                setPrice();
                setOpen(false)
                setSelectedCompanyCountry();

                message.success("Plan created")
            } else {
                message.error("Somthing wrong")
            }
        }

    }
    const handleAddPrice = async () => {
        try {
            let key = selectedCompanyCountry?.currency?.code;
            let body = {
                [key]: price,
                id: key
            }
            let array = [...formData?.price ? formData?.price : []]
            let check = array.some(obj => obj.hasOwnProperty(key));
            console.log(check)
            if (!check) {
                array.push(body);
                setFormData(prev => ({ ...prev, price: array }))
                setSelectedCompanyCountry();
                setPrice();
            } else {
                message.error("Currency Allready exist")
            }

        } catch (e) {
            throw (e)
        }
    }

    const confirm = (e) => {
        handleRemovePrice(e)
    };
    const cancel = (e) => {
    };

    const handleRemovePrice = (item) => {
        let array = [...formData?.price ? formData?.price : []]
        array = array.filter(obj => !obj.hasOwnProperty(item?.id));
        setFormData(prev => ({ ...prev, price: array }))

    }
    return (
        <div className=' flex flex-col gap-10'>
            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={8}>

                    <p style={{ color: Colors?.LightText }}>Plan Name </p>
                </Col>
                <Col span={16}>
                    <Input
                        placeholder='Write your plan name'
                        value={formData?.title}
                        onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                    />
                </Col>
            </Row>
            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={8}>

                    <p style={{ color: Colors?.LightText }}>No. of Document </p>
                </Col>
                <Col span={16}>
                    <InputNumber

                        value={formData?.numberOfDocument}
                        onChange={(e) => setFormData(prev => ({ ...prev, numberOfDocument: e }))}
                        type='number' className=' w-full' />
                </Col>
            </Row>
            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={8}>

                    <p style={{ color: Colors?.LightText }}>No. of Members </p>
                </Col>
                <Col span={16}>
                    <InputNumber
                        value={formData?.numberOfUser}
                        onChange={(e) => setFormData(prev => ({ ...prev, numberOfUser: e }))}
                        type='number' className=' w-full' />
                </Col>
            </Row>

            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={8}>

                    <p style={{ color: Colors?.LightText }}>Description </p>
                </Col>
                <Col span={16}>
                    <TextArea
                        placeholder='Write description'
                        value={formData?.description}
                        onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                        className=' w-full' />
                </Col>
            </Row>
            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={8}>

                    <p style={{ color: Colors?.LightText }}>File Upload</p>
                </Col>
                <Col span={16}>
                    <Switch checked={formData?.fileUpload} onClick={() => setFormData(prev => ({ ...prev, fileUpload: !formData?.fileUpload }))} />
                </Col>
            </Row>
            {formData?.fileUpload ? <Row Row className='flex justify-between  items-center flex-wrap'>
                <Col span={8}>

                    <p style={{ color: Colors?.LightText }}>Storage in MB </p>
                </Col>
                <Col span={16}>
                    <InputNumber

                        value={formData?.storage}
                        onChange={(e) => setFormData(prev => ({ ...prev, storage: e }))}
                        type='number' className=' w-full' />
                </Col>
            </Row> : ""
            }
            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={8}>

                    <p style={{ color: Colors?.LightText }}>Mobile OTP Sign </p>
                </Col>
                <Col span={16}>
                    <Switch checked={formData?.mobileSign} onClick={() => setFormData(prev => ({ ...prev, mobileSign: !formData?.mobileSign }))} />
                </Col>
            </Row>
            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={8}>

                    <p style={{ color: Colors?.LightText }}>Recommended </p>
                </Col>
                <Col span={16}>
                    <Switch checked={formData?.popular} onClick={() => setFormData(prev => ({ ...prev, popular: !formData?.popular }))} />
                </Col>
            </Row>

            <Divider />
            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={3}>

                    <p style={{ color: Colors?.LightText }}>Code </p>
                </Col>
                <Col span={3}>

                    <p style={{ color: Colors?.LightText }}> MP </p>
                </Col>
                <Col span={3}>
                    <p style={{ color: Colors?.LightText }}>OMP</p>

                </Col>
                <Col span={3}>
                    <p style={{ color: Colors?.LightText }}> OYP</p>
                </Col>
                <Col span={3}>
                    <p style={{ color: Colors?.LightText }}> </p>
                </Col>

            </Row>
            {
                formData?.price?.map((item) => <div>
                    <Row className='flex justify-between  items-center flex-wrap'>
                        <Col span={3}>

                            <p style={{ color: Colors?.LightText }}>{item?.id} </p>
                        </Col>
                        <Col span={3}>

                            <p style={{ color: Colors?.LightText }}>{item?.[item?.id]?.monthlyPrice} </p>
                        </Col>
                        <Col span={3}>
                            <p style={{ color: Colors?.LightText }}>{item?.[item?.id]?.offerMonthlyPrice} </p>

                        </Col>
                        <Col span={3}>
                            <p style={{ color: Colors?.LightText }}>{item?.[item?.id]?.offerYearlyPrice} </p>
                        </Col>
                        <Col span={3}>
                            <Popconfirm
                                title="Delete the task"
                                description="Are you sure to delete this plan?"
                                onConfirm={() => confirm(item)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <CloseOutlined />
                            </Popconfirm>
                        </Col>
                    </Row>
                </div>


                )
            }
            <Divider />

            {
                !true ? ""
                    :
                    <div className=' flex flex-col gap-5'>

                        <Row className='flex justify-between  items-center flex-wrap'>
                            <Col span={8}>

                                <p style={{ color: Colors?.LightText }}>Currency </p>
                            </Col>
                            <Col span={16}>
                                <CountrySelectComponent
                                    selectedCountry={selectedCompanyCountry}
                                    setSelectedCountry={setSelectedCompanyCountry}
                                    isSelected={isCompanySelected}
                                    setIsSelected={setIsCompanySelected}
                                />
                            </Col>
                        </Row>
                        <Row className='flex justify-between  items-center flex-wrap'>
                            <Col span={8}>

                                <p style={{ color: Colors?.LightText }}>Monthly Price </p>
                            </Col>
                            <Col span={16}>
                                <InputNumber
                                    value={price?.monthlyPrice}
                                    onChange={(e) => setPrice(prev => ({ ...prev, monthlyPrice: e }))}
                                    type='number' className=' w-full' />
                            </Col>
                        </Row>
                        <Row className='flex justify-between  items-center flex-wrap'>
                            <Col span={8}>

                                <p style={{ color: Colors?.LightText }}>Monthly Offer Price </p>
                            </Col>
                            <Col span={16}>
                                <InputNumber
                                    value={price?.offerMonthlyPrice}
                                    onChange={(e) => setPrice(prev => ({ ...prev, offerMonthlyPrice: e }))}
                                    type='number' className=' w-full' />
                            </Col>
                        </Row>
                        <Row className='flex justify-between  items-center flex-wrap'>
                            <Col span={8}>

                                <p style={{ color: Colors?.LightText }}>Yearly Offer Price</p>
                            </Col>
                            <Col span={16}>
                                <InputNumber
                                    value={price?.offerYearlyPrice}
                                    onChange={(e) => setPrice(prev => ({ ...prev, offerYearlyPrice: e }))}
                                    type='number' className=' w-full' />
                            </Col>
                        </Row>
                    </div>
            }
            <Row className='flex justify-between  items-center flex-wrap'>
                <Col span={24} className=' flex justify-end'>
                    <Button disabled={!selectedCompanyCountry || !price?.monthlyPrice || !price?.offerMonthlyPrice || !price?.offerYearlyPrice} onClick={handleAddPrice} type='link'> Add Price</Button>
                </Col>
            </Row>
            <Row className='flex justify-end gap-5 items-center flex-wrap'>

                <Button size='large' onClick={handleClose}>  Cancle</Button>
                {formData?._id ?
                    <Button size='large' className=' w-28' disabled={!(formData?.price?.length > 0) || !(formData?.numberOfDocument > 0) || !(formData?.fileUpload ? formData?.storage > 0 : true) || !(formData?.numberOfUser > 0) || !(formData?.title)} type='primary' onClick={handleEdit}>Save</Button>

                    : <Button size='large' disabled={!(formData?.price?.length > 0) || !(formData?.numberOfDocument > 0) || !(formData?.storage > 0) || !(formData?.numberOfUser > 0) || !(formData?.title)} type='primary' onClick={handleAddPlan}>Create</Button>
                }
            </Row>
        </div >
    )
}
