import axios from "axios";

export let _localStorage = {};

export const updateLocalStorage = async (data) => {
    // UPDATE LOCAL STORAGE
    console.log("hel")
    localStorage.removeItem("userData");

    window.location.replace('/');
};

export const BASEURL = `https://api.signizy.com/`
// export const BASEURL = `http://192.168.1.39:3002/`

// RETURN TOKEN ACCORDING TO CURRENT PANEL USER
const returnToken = () => {
    let token = localStorage.getItem('userData');
    token = token ? JSON.parse(token) : null
    return `${token?.user?.token}`

};

// INTERCEPTORS FOR REQUESTS 
axios.interceptors.request.use(
    function (config) {
        config.headers.Authorization = returnToken();
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);


export const post = async (url, data) => {
    try {
        const res = await axios.post(BASEURL + url, data);
        return { statusCode: res?.status, data: res.data };
    } catch (e) {
        if (e.response?.status === 401) {
            updateLocalStorage();
        }
        return {
            statusCode: e?.response?.status || 404,
            message:
                e?.response?.data === undefined
                    ? e?.message
                    : e?.response?.data?.error?.message || e?.response?.data?.message,
        };
    }
};

export const processFileName = (fileName, count) => {
    // Splitting the file name by '.' to get the name and extension
    const [name, extension] = fileName.split('.');

    // Extracting the first and last 5 characters of the name
    const startChars = name.slice(0, count);
    const endChars = name.slice(-count);

    // Creating the new file name by combining startChars, endChars, and extension
    const newFileName = `${startChars}_${endChars}.${extension}`;

    return newFileName;
};

export const capitalizeAllWords = (sentence) => {
    // Split the sentence into an array of words
    const words = sentence.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    // Join the capitalized words back into a sentence
    const capitalizedSentence = capitalizedWords.join(" ");

    return capitalizedSentence;
}
export const patch = async (url, data) => {
    try {
        const res = await axios.patch(BASEURL + url, data);
        return { statusCode: res.status, data: res.data };
    } catch (e) {
        if (e.response?.status === 401) {
            updateLocalStorage();
        }
        return {
            statusCode: e?.response?.data?.error?.statusCode || 404,
            message:
                e.response.data === undefined
                    ? e?.message
                    : e?.response?.data?.error?.message,
        };
    }
};

export const get = async (url) => {
    try {
        const res = await axios.get(BASEURL + url);
        return { statusCode: res.status, data: res.data };
    } catch (e) {
        if (e.response?.status === 401) {
            updateLocalStorage();
        }
        return {
            statusCode: e?.response?.status || 404,
            message:
                e.response.data === undefined
                    ? e?.message
                    : e?.response?.data?.message,
        };
    }
};


export const deletethis = async (url) => {
    try {
        const res = await axios.delete(BASEURL + url);
        return { statusCode: res.status, data: res.data };
    } catch (e) {
        if (e.response?.status === 401) {
            updateLocalStorage();
        }
    }
};


// FORMAT DATE TIME 
export const formatDateFunction = (_date) => {
    // Parse the date string to a Date object
    const date = new Date(_date);

    // Define the month names
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Get the day, month, and year parts
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Format the date to "DD MMMM YYYY" format
    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

    return formattedDate;
};



// GET DATE  ONLY /
export const getDate = (_date) => {
    // Parse the date string to a Date object
    const date = new Date(_date);

    // Get the day of the month (1-31)
    const dayOfMonth = date.getDate();

    return dayOfMonth;
};

// GET DATE AND YEAR 
export const getDateYear = (_date) => {
    // Parse the date string to a Date object
    const date = new Date(_date);

    // Get the month and year parts
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year

    // Concatenate the month and year parts
    const formattedDate = `${month} ${year}`;

    return formattedDate;
};