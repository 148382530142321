import React, { useState } from 'react';
import { Button, Drawer, Radio, Space, } from 'antd';
import { BarsOutlined } from '@ant-design/icons';
import SideManubar from './SideManubar';
import { Colors } from '../theme/Thems';
const DrawerNav = () => {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    return (
        <>
            <div className='flex h-12 shadow w-screen justify-end items-center px-3'>
                <BarsOutlined style={{ color: Colors?.primaryColor }} className={` text-[30px] `} onClick={showDrawer} />
                {/* <Button type="primary" onClick={showDrawer}>
                    Open
                </Button> */}
            </div>
            <Drawer
                // title="Basic Drawer"
                placement={placement}
                closable={false}
                onClose={onClose}
                open={open}
                key={placement}
                width={250}
            >
                <SideManubar />
            </Drawer>
        </>
    );
};
export default DrawerNav;