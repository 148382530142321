import { Checkbox, Col, Input, InputNumber, Row, Select, Slider } from 'antd'
import React, { useEffect, useState } from 'react'
import { Colors } from '../theme/Thems';
import { get } from '../Services/api-services';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';

export default function TeamFilter(props) {
    const [planData, setPlanData] = useState([]);
    const onChangeMember = (newValue) => {
        props.setFilterData(prevState => (
            { ...prevState, member: newValue }));
    };
    const onChangeNumberOfDoc = (newValue) => {
        props.setFilterData(prevState => (
            { ...prevState, numberOfDoc: newValue }));
    };


    const getData = async () => {
        const res = await get('plan');
        if (res?.statusCode === 200) {
            setPlanData(res?.data)
        }
    }

    const handlePlanChange = async (item) => {

        const __index = props?.filterData?.plans?.indexOf(item?._id);
        if (__index >= 0) {
            let array = [...props?.filterData?.plans];
            array.splice(__index, 1)
            props.setFilterData(prevState => (
                { ...prevState, plans: array }));
        } else {

            const array = [...props?.filterData?.plans ? props?.filterData?.plans : [], item?._id];

            props.setFilterData(prevState => (
                { ...prevState, plans: array }));

        }

    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <div className='flex flex-col gap-10 pt-10'>

            <Row className='flex items-center'>
                <Col span={6}>
                    <p>Members</p>
                </Col>

                <Col span={8}>
                    <InputNumber
                        placeholder='from'
                        type='number'
                        min={0}
                        max={10000}
                        style={{
                            margin: '0 16px',
                        }}
                        onChange={(e) => {
                            props.setFilterData(prevState => (
                                { ...prevState, memberFrom: parseInt(e) }));
                        }}
                        value={typeof props?.filterData?.memberFrom === 'number' ? props?.filterData?.memberFrom : ""}
                    />
                </Col>
                <Col span={8}>
                    <InputNumber
                        type='number'
                        placeholder='to'
                        min={0}
                        max={10000}
                        style={{
                            margin: '0 16px',
                        }}
                        onChange={(e) => {
                            props.setFilterData(prevState => (
                                { ...prevState, memberTo: parseInt(e) }));
                        }}
                        value={props?.filterData?.memberTo ? props?.filterData?.memberTo : ""}
                    />
                </Col>
            </Row>
            <Row className='flex items-center'>
                <Col span={6}>
                    <p>No. of Docs</p>
                </Col>
                <Col span={8}>

                    <InputNumber
                        placeholder='from'
                        min={0}
                        max={10000}
                        style={{
                            margin: '0 16px',
                        }}
                        onChange={(e) => {
                            props.setFilterData(prevState => (
                                { ...prevState, numberOfDocFrom: parseInt(e) }));
                        }}
                        value={props?.filterData?.numberOfDocFrom ? props?.filterData?.numberOfDocFrom : ""}
                    />
                </Col>
                <Col span={8}>
                    <InputNumber
                        placeholder='to'
                        min={0}
                        max={10000}
                        style={{
                            margin: '0 16px',
                        }}
                        onChange={(e) => {
                            props.setFilterData(prevState => (
                                { ...prevState, numberOfDocTo: parseInt(e) }));
                        }}
                        value={props?.filterData?.numberOfDocTo ? props?.filterData?.numberOfDocTo : ""}
                    />
                </Col>
            </Row>
            <Row >
                <Col span={6}>
                    <p>Plans</p>
                </Col>
                <Col span={12} className='flex flex-col gap-4'>
                    {planData?.map((item) => <div className='flex gap-3'>
                        <Checkbox checked={props.filterData?.plans?.indexOf(item?._id) >= 0} onChange={() => handlePlanChange(item)} /> <p style={{ color: Colors?.LightTextTwo }} >{item?.title}</p>
                    </div>)}
                </Col>

            </Row>
            <Row >
                <Col span={6} className='flex items-center'  >
                    <div style={{ color: Colors.primaryColor }} className='  cursor-pointer flex gap-3 items-center ' onClick={() => props.setFilterData(prev => ({ ...prev, sortOrder: !props?.filterData?.sortOrder }))} ><p>Sort By</p> {props?.filterData?.sortOrder ? <SortAscendingOutlined className=' text-xl' /> : <SortDescendingOutlined className=' text-xl' />}</div>
                </Col>
                <Col span={12} className='flex flex-col gap-4 justify-center'>
                    <Select
                        defaultValue="createdAt"
                        // style={{ width: 120 }}
                        onChange={(e) => { props.setFilterData(prev => ({ ...prev, sortValue: e })) }}
                        allowClear
                        options={[{ value: 'createdAt', label: 'Joining on' }, { value: 'name', label: 'Name' }, { value: 'ownerName', label: 'Owner' }, { value: 'ownerEmail', label: 'Email' }, { value: 'ownerMobile', label: 'Phone No' }]}
                    />
                    {/* {planData?.map((item) => <div className='flex gap-3'>
                        <Checkbox checked={props.filterData?.plans?.indexOf(item?._id) >= 0} onChange={() => handlePlanChange(item)} /> <p style={{ color: Colors?.LightTextTwo }} >{item?.title}</p>
                    </div>)} */}
                </Col>

            </Row>
        </div>
    )
}
