export const Colors = {
    primaryColor: "#1677FF",

    backgroundColor: "white",

    TextColorInDarkBackground: "#EEEEEE",

    TextColorInLightBackground: "#D6E3FD",

    LightText: "#424D51",

    LightTextTwo: "#8E8E8E",

    DarkBg: "#000000",
    secondryBtnBg: '#F1F6FF',



}