import React from "react";
import Login from "../screens/Login";
import { Route, Routes } from "react-router-dom";
import Home from "../screens/Home";
import Dashboard from "../screens/Dashboard";
import TeamHome from "../screens/Teams/TeamHome";
import TeamList from "../screens/Teams/TeamList";
import SalesHome from "../screens/Sales/SalesHome";
import SalesList from "../screens/Sales/SalesList";
import Settings from "../screens/Settings";
import Coupons from "../screens/Coupons";
import TeamDetails from "../screens/TeamDetails";
import SalesDetails from "../screens/Teams/SalesDetails";


export default function MainRoutes() {
    return (
        <Routes>
            <Route path="" element={<Login />} />
            <Route path="/login" element={<Login />} />

            {/* ###  Home routes */}
            <Route path="/Home" element={<Home />}>
                <Route path="" element={<Dashboard />} />
                <Route path="Dashboard" element={<Dashboard />} />

                <Route path="Teams" element={<TeamHome />}>
                    <Route path="" element={<TeamList />} />
                    <Route path=":Team_details" element={<TeamDetails />} />

                </Route>

                <Route path="Sales" element={<SalesHome />}>
                    <Route path="" element={<SalesList />} />
                    <Route path=":Team_details" element={<SalesDetails />} />

                </Route>
                <Route path="Coupons" element={<Coupons />} />

                <Route path="Settings" element={<Settings />} />

            </Route>


        </Routes>
    );
}