import React from 'react'
import { H2 } from '../theme/StylingComponent'

export default function Coupons() {
    return (
        <div>
            <H2 title={"Coupons"} />
        </div>
    )
}
