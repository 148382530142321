
export const H1 = ({ title, align, font }) => {
    return (
        <h1 className={`text-3xl text-${align} lg:text-4xl  font-${font}`}>{title}</h1>
    )
}

export const H2 = ({ title }) => {
    return (
        <h1 className={`text-xl lg:text-2xl capitalize  font-medium`}>{title}</h1>
    )
}

export const H3 = ({ title, disabled }) => {
    return (
        <h1 className={`text-lg lg:text-xl   font-medium`}>{title}</h1>
    )
}

export const H4 = ({ title, align, font }) => {
    return (
        <h1 className={`text-base capitalize  lg:text-lg text-${align} font-${font} `}>{title}</h1>
    )
}

export const H5 = ({ title, font, align }) => {
    return (
        <h1 className={`text-sm whitespace-nowrap lg:text-base text-${align}  font-${font}`}>{title}</h1>
    )
}

export const H6 = ({ title }) => {
    return (
        <h1 className={`text-xs lg:text-sm  font-normal`}>{title}</h1>
    )
}

export const Span = ({ title }) => {
    return (
        <h1 className={`text-xs mt-3 lg:text-sm  font-normal`}>{title}</h1>
    )
}

export const P = ({ title, align }) => {
    return (
        <h1 className={`text-xs lg:text-sm text-${align}   font-normal`}>{title}</h1>
    )
}

export const XS = ({ title, align, color, font }) => {
    return (
        <p className={`text-[10px] lg:text-xs whitespace-nowrap text-[${color}]   font-${font} `}>{title}</p>

    )
}

export const Price = ({ title, align }) => {
    return (
        <div className='relative'>
            {/* <span className='w-[52px] bg-[#AAAAAA] h-[2px] absolute top-1/2 left-0'></span> */}
            <h6 className='text-xl line-through text-[#AAAAAA]'>{title}</h6>
        </div>
    )
}



