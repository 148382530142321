import React, { useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, message } from 'antd';
import { EmmailIcon, PasswordIcon } from '../components/Icons';
import { Colors } from '../theme/Thems';
import { XS } from '../theme/StylingComponent';
import { emailRegex } from '../Regex/Regex';
import { get, post } from '../Services/api-services';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
const signizyLogo = require('../Assets/SignizyLogo.png')
export default function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [remember, setRemember] = useState(false);
    const [authState, setAuthState] = useRecoilState(userAuthState);
    const navigate = useNavigate()
    const handleLogin = async () => {
        try {
            let body = {
                email: email,
                password: password,
                rememberMe: remember,
            }
            const res = await post('auth/admin/login', body);
            if (res?.statusCode === 200) {
                setAuthState(res?.data);
                navigate('/home')
            } else {
                // message.error(res)
                message.error(res?.message)
            }
        } catch (e) {
            throw (e)
        }
    }

    useEffect(() => {
        if (authState?.id && authState?.token) {
            navigate('/home')
        }
        document.title = "Login"

    }, [])

    return (
        <div className=' flex flex-1 justify-center items-center'>
            <div className='flex flex-col gap-5 w-[90vw] sm:w-[80vw] md:w-1/2 lg:w-1/3 xl:w-1/4' >
                <div className='flex justify-center pb-20'><img className='h-16' src={signizyLogo} /></div>
                <Input
                    prefix={<EmmailIcon color={Colors.LightTextTwo} />}
                    size="large"
                    placeholder="Email"
                    status={emailRegex.test(email) || email?.length === 0 || !email ? '' : "error"}

                    onChange={(e) => setEmail(e?.target?.value)}
                />
                <Input.Password
                    onChange={(e) => setPassword(e?.target?.value)}
                    status={password?.length > 5 || password?.length === 0 || !password ? '' : "error"}

                    size='large'
                    // style={inputStyle}
                    placeholder="Password"
                    prefix={<PasswordIcon color={Colors.LightTextTwo} />}
                // status={formData?.password?.length > 0 && !passwordRegex?.test(formData.password) ? 'error' : ''}
                />
                <div className=' flex justify-between'>
                    <div className=' flex items-center gap-2'> <Checkbox onClick={() => setRemember(!remember)} /> <XS title={"Remember me"} color={Colors?.LightText} /></div>
                    <div> <XS title={"Forget Password"} color={Colors?.LightTextTwo} /> </div>
                </div>
                <Button
                    disabled={!emailRegex?.test(email) || password?.length < 6}
                    onClick={handleLogin}
                    type='primary'>Log In</Button>
            </div>
        </div>
    )
}
