import { CheckOutlined, CloseOutlined, EditOutlined, PlusCircleOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, FloatButton, Input, InputNumber, Skeleton, Space, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { Colors } from '../theme/Thems';
import { get, patch } from '../Services/api-services';
import { AddIcon, EditIcon } from '../components/Icons';
import { H2, H3 } from '../theme/StylingComponent';
import AddPlanDrawer from '../components/AddPlanDrawer';
import AddPlans from '../components/AddPlans';

export default function Settings() {
    const [open, setOpen] = useState(false);

    const [edit, setEdit] = useState(false);
    const [setting, setSetting] = useState([]);
    const [plans, setPlans] = useState([]);
    const [formData, setFormData] = useState({ fileUpload: false, mobileSign: false, popular: false });
    const [country, setCountry] = useState();
    const [TrialLoading, setTrialLoading] = useState(true);
    const [planLoading, setPlanLoading] = useState(true);
    const getSetting = async () => {
        setTrialLoading(true)
        const res = await get('setting');
        if (res?.statusCode === 200) {
            setSetting(res?.data[0])
        }
        setTrialLoading(false)

    }
    const getPlan = async () => {
        setPlanLoading(true)
        const res = await get('plan');
        if (res?.statusCode === 200) {
            setPlans(res?.data)
        }
        setPlanLoading(false)

    }
    const getCountry = async () => {
        const res = await get('auth/country');
        if (res?.statusCode === 200) {
            setCountry(res?.data)
        }
    }
    const handleSaveTrial = async () => {
        const res = await patch(`setting/${setting?._id}`, { trialPeriod: setting?.trialPeriod });
        if (res?.statusCode === 200) {
            getSetting();
            setEdit(false)
        }
    }

    const handleEdit = async (item) => {
        let objects = { ...item?.price }
        let keys = Object.keys(objects)
        let array = []
        for (let index = 0; index < keys.length; index++) {
            const element = objects[keys[index]];
            let body = { [keys[index]]: element, id: keys[index] }
            array.push(body)

        }
        console.log({ ...item, price: array })
        setFormData({ ...item, price: array })
        setOpen(true)

    }

    useEffect(() => {
        getSetting();
        getPlan();
    }, [])
    return (
        <div className='flex flex-1 flex-col gap-10'>
            <div className='pt-10'>

                <H2 title={"Settings"} />
            </div>


            <div className='w-full flex flex-col gap-5'>

                <H3 title={'Trial Period'} />
                {!TrialLoading ? <Space.Compact style={{ width: '100%' }}>
                    <InputNumber value={setting?.trialPeriod} disabled={!edit} onChange={(e) => { setSetting(prev => ({ ...setting, trialPeriod: e })) }} style={{ width: 300 }} type='number' defaultValue="Combine input and button" />
                    <Button >{edit ? <SaveOutlined onClick={() => handleSaveTrial()} style={{ color: Colors?.primaryColor }} /> : <EditOutlined onClick={() => setEdit(true)} style={{ color: Colors?.primaryColor }} />}</Button>
                </Space.Compact> : <Skeleton.Input block={true} />}
            </div>
            <H3 title={'Subscription'} />
            {!planLoading ? <div className='flex  gap-16 sm:gap-5 flex-wrap '>
                <AddPlanDrawer open={open} setOpen={setOpen} components={<AddPlans length planLength={plans?.length} getPlan={getPlan} formData={formData} setOpen={setOpen} country={country} setFormData={setFormData} />} />


                {plans?.map((item) =>
                    <ConfigProvider
                        theme={{
                            components: {
                                Card: {
                                    /* here is your component tokens */
                                    actionsBg: "#508BFD17"
                                },
                            },
                        }}
                    >
                        <Card
                            title={item?.title}

                            className=' sm:w-[320px]  w-full '
                            actions={[

                                <EditOutlined key="edit" onClick={() => handleEdit(item)} />,
                            ]}
                        >
                            <div className=' h-[40vh] sm:h-[40vh]'>
                                <div style={{ color: Colors?.LightText }}>{item?.numberOfDocument > 0 ? < CheckOutlined className='pr-2' /> : <CloseOutlined className='pr-2' />}  {`${item?.numberOfDocument} Documents created in one month`} </div>
                                <div style={{ color: Colors?.LightText }}>{item?.numberOfUser > 0 ? < CheckOutlined className='pr-2' /> : <CloseOutlined className='pr-2' />}  {`${item?.numberOfUser} Members can add`} </div>
                                <div style={{ color: Colors?.LightText }}>{item?.mobileSign ? < CheckOutlined className='pr-2' /> : <CloseOutlined className='pr-2' />}  Mobile OTP verification </div>
                                <div style={{ color: Colors?.LightText }}>{item?.popular ? < CheckOutlined className='pr-2' /> : <CloseOutlined className='pr-2' />}  Recommended </div>
                                <div style={{ color: Colors?.LightText }}>{item?.fileUpload ? < CheckOutlined className='pr-2' /> : <CloseOutlined className='pr-2' />}  {` File Uploaded under ${item?.storage} Mb `} </div>
                                <div dangerouslySetInnerHTML={{ __html: item?.description ? item?.description : '<div></div>' }} ></div>

                            </div>
                        </Card>
                    </ConfigProvider>


                )
                }
            </div>
                :
                <div>

                    <Skeleton />
                    <Skeleton />
                    <Skeleton />

                </div>
            }

        </div >
    )
}
