import React, { useEffect, useState } from 'react'
import { H2 } from '../../theme/StylingComponent'
import { DatePicker } from 'antd';
import Tables from '../../components/Tables';
import { post } from '../../Services/api-services';
import FilterDrawer from '../../components/FilterDrawer';
import TeamFilter from '../../components/TeamFilter';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
const columns = [
    {
        title: 'Joined On',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 300,
        render: (text) => <p>{moment(new Date(text)).format("MMM Do YY")}</p>,

    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 300,
    },
    {
        title: 'Owner',
        dataIndex: 'ownerName',
        key: 'ownerName',
        width: 300,
    },
    {
        title: 'Email',
        dataIndex: 'ownerEmail',
        key: 'ownerEmail',
        width: 250,
    },

    {
        title: 'Phone Number',
        dataIndex: 'ownerMobile',
        key: 'ownerMobile',
        width: 150,
    },
    {
        title: 'Members',
        dataIndex: 'member',
        key: 'member',
        width: 100,
    },
    {
        title: 'No. of Doc',
        dataIndex: 'numberOfDoc',
        key: 'numberOfDoc',
        width: 100,
    },

    {
        title: 'Plan',
        dataIndex: 'planTitle',
        key: 'planTitle',
        width: 300,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 300,
    },
];
export default function TeamList() {
    const { RangePicker } = DatePicker;
    const [dateRange, setDateRange] = useState();
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoding] = useState(true)
    const [filterData, setFilterData] = useState();
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState({ page: 0, pageSize: 10 })
    const navigate = useNavigate();

    const getTeamsData = async (data) => {
        setLoding(true);
        try {
            const res = await post('admin/getTeamList', data);
            if (res?.statusCode === 200) {
                setDataSource(res?.data?.data);
                setTotal(res?.data?.FilterCount);

            } else {

            }
        } catch (e) {

        }
        setLoding(false)
    }

    useEffect(() => {
        getTeamsData({ dateFilter: dateRange, filterData: filterData, pagination: pagination });
        console.log('i fire once');


    }, [dateRange, pagination])
    const applyFilter = async () => {
        getTeamsData({ dateFilter: dateRange, filterData: filterData, pagination: pagination });
    }
    const reset = async () => {
        setFilterData()
        getTeamsData({});

    }

    const setData = async ({ record, rowIndex }) => {
        try {
            console.log(record)
            navigate(record?.name ? record?.name?.replace(/\s/g, '_') : "Team_Detail", { state: record })
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className='flex flex-1 flex-col gap-5 ' style={{ maxWidth: "100%" }}>
            <div className='flex w-full justify-between flex-wrap'>
                <H2 title={"Teams"} />
                <div>
                    <RangePicker
                        style={{ borderRadius: 3 }}
                        onChange={(value, dateString) => { setDateRange(dateString) }}
                    />
                </div>
            </div>
            <div className='flex justify-end pb-10' ><FilterDrawer applyFilter={applyFilter} reset={reset} components={< TeamFilter setFilterData={setFilterData} filterData={filterData} />} /></div>


            <Tables dataSource={dataSource} loading={loading} total={total} columns={columns} setData={setData} setPagination={setPagination} />


        </div >
    )
}
