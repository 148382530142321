import { ConfigProvider, Table } from 'antd';
import React, { useRef } from 'react'
import { Colors } from '../theme/Thems';

const dataSource = [
    {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
    },
    {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
    },
];



export default function Tables(props) {
    const TableRef = useRef(null);
    return (

        <div div ref={TableRef} className='flex flex-1 md:max-w-[73vw] lg:max-w-[78vw]  xl:max-w-[83vw]   ' style={{ maxHeight: TableRef?.current?.offsetHeight, }} >
            < ConfigProvider
                theme={{
                    components: {
                        Table: {
                            /* here is your component tokens */
                            borderColor: "none",
                            headerColor: Colors.DarkBg,
                            colorText: Colors.LightText

                        },
                    },
                }}
            >
                < Table
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { props?.setData({ record, rowIndex }) }, // click row
                        };
                    }}
                    scroll={{
                        y: parseInt(TableRef?.current?.offsetHeight) - 150,
                        x: 300
                    }}
                    loading={props.loading}
                    className='flex-1'
                    dataSource={props?.dataSource}
                    columns={props?.columns}
                    pagination={{ total: props.total, defaultPageSize: 10, page: true,  pageSizeOptions: [10, 15, 30, 50, 100, 500, 1000], onChange: (page, pagesize) => props.setPagination({ page: page, pageSize: pagesize }) }}
                />
            </ConfigProvider>

        </div >

    );




}